<template>
  <div :key="updateKey">
    <div v-if="filter">
      <editLink
        :user="filter"
        @close="
          () => {
            setFilter(filter)
          }
        "
      ></editLink>
    </div>
    <b-flix-h1>Server-Logs</b-flix-h1>
    <loggingTable
      :backButton="true"
      :ID="filter"
      @changed="
        (f) => {
          filter = f
        }
      "
    ></loggingTable>
  </div>
</template>
<script>
export default {
  name: 'loggingOverview',
  components: {
    editLink: () => {
      return import('./editLink.vue')
    },
    loggingTable: () => {
      return import('./loggingTable.vue')
    }
  },
  props: {
    ID: {
      type: [String, Number, Boolean],
      default() {
        return false
      }
    }
  },
  data() {
    return {
      logs: [],
      totalLogs: [],
      offset: false,
      updateKey: 0,
      loading: true,
      filter: this.ID
    }
  },
  computed: {},
  mounted() {},
  methods: {
    setFilter(user) {
      this.filter = user === this.filter ? false : user
      if (!this.filter) {
        this.logs = this.totalLogs
        this.updateKey++
        return false
      }
      this.loading = true
      this.getSpecific()
    },
    getFilter(logs) {
      if (!this.filter) {
        return logs
      }
      const newLogs = []
      logs.forEach((log) => {
        if (log.user === this.filter) {
          newLogs.push(log)
        }
      })
      return newLogs
    },
    getSpecific() {
      this.$flix_post({
        url: 'notes/get_specific',
        data: {
          user: this.$getUserVariables().user.md5_id,
          ID: this.filter,
          category: [
            'validation',
            'appointment begin',
            'checkin',
            'checkout',
            'commitment',
            'cancellation',
            'booking',
            'validation_reminder',
            'mail'
          ]
        },
        callback: (ret) => {
          if (ret.data[0]) {
            this.logs = ret.data[1]
            this.loading = false
            this.updateKey++
          }
        }
      })
    },
    getCategory(cat) {
      if (cat.includes('appointment')) {
        return this.$tc('message.reminder', 1)
      }
      if (cat.includes('booking')) {
        return this.$tc('message.appointments', 1)
      }
      if (cat.includes('checkin')) {
        return this.$tc('message.checkIn', 1)
      }
      if (cat.includes('checkout')) {
        return this.$tc('message.checkOut', 1)
      }
      if (cat.trim() === 'appointment') {
        return this.$tc('message.appointments', 1)
      }
      return this.$tc('message.' + cat, 1)
    },
    setNext() {
      this.loading = true
      this.offset += 1
      this.getLogs()
    },
    getLogs() {
      this.loading = true
      this.$flix_post({
        url: 'log/get',
        data: {
          user: this.$getUserVariables().user.md5_id,
          offset: this.offset
        },
        callback: (ret) => {
          if (ret.data[0]) {
            const logs = JSON.parse(JSON.stringify(this.totalLogs))

            ret.data[1].forEach((log) => {
              logs.push(log)
            })

            this.logs = this.getFilter(logs)
            this.totalLogs = logs
            this.loading = false
            this.updateKey++
          }
        }
      })
    }
  }
}
</script>
<style lang="sass" scoped>

table
  tr
    &:nth-child(even)
      background: #eee
    td
      padding: 10px
</style>
